<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    app
    floating
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
  >
    <template #prepend>
      <v-list-item two-line>
        <v-list-item-action v-if="sidebarMenu">
          <v-icon
            @click.stop="
              sidebarMenu = !sidebarMenu
              $emit('update:sidebar', sidebarMenu)
            "
          >
            mdi-chevron-left
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <router-link to="/" class="black--text text-decoration-none">
            <img
              width="50%"
              height="70%"
              src="@/assets/images/logo/with-black.png"
              class="mt-3 ml-3"
            />
          </router-link>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-list class="nav-list mx-5 mr-8 mt-15 ">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.url"
        active-class="yellow"
        class="my-5"
      >
        <v-list-item-avatar
          size="20"
          tile
          class="text--opacity-75 text-subtitle-2 mr-3"
        >
          <v-img
            alt="ProfileImg"
            :src="$route.path == item.url ? item.activeImg : item.img"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text--opacity-75 text-subtitle-2">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-list class="mb-15 mx-5 mr-8">
        <v-list-item active-class="yellow" @click="logout()">
          <v-list-item-avatar
            size="20"
            tile
            class="text--opacity-75 text-subtitle-2 mr-3"
          >
            <v-img alt="logout" src="/images/icon/talent/logout-grey.png" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text--opacity-75">
              {{ $t('logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    sidebar: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      sidebarMenu: this.sidebar,
      toggleMini: false,
      items: [
        {
          title: 'Dashboard',
          img: '/images/icon/talent/dash-grey.png',
          activeImg: '/images/icon/talent/dash-white.png',
          url: '/talent/dashboard',
        },
        {
          title: 'Session Types',
          img: '/images/icon/talent/session-grey.png',
          activeImg: '/images/icon/talent/session-white.png',
          url: '/talent/sessions',
        },
        {
          title: 'Availability',
          img: '/images/icon/talent/session-grey.png',
          activeImg: '/images/icon/talent/session-white.png',
          url: '/talent/availability',
        },
        {
          title: 'Appointments',
          img: '/images/icon/talent/appoin-grey.png',
          activeImg: '/images/icon/talent/appoin-white.png',
          url: '/talent/appointments',
        },
        // {
        //   title: "Help",
        //   img: "/images/icon/talent/help-grey.png",
        //   activeImg: "/images/icon/talent/help-white.png",
        //   url: "/talent/help",
        // },
        {
          title: 'Profile',
          img: '/images/icon/talent/edit.png',
          activeImg: '/images/icon/talent/edit-white.png',
          url: '/talent/profile',
        },
      ],
    }
  },
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
  },
  watch: {
    sidebar(newVal) {
      this.sidebarMenu = newVal
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-list {
  margin-top: 2px;
  .v-list-item--active {
    border-radius: 4px !important;
    .v-list-item__title {
      color: white;
    }
  }
}
.mobile-view {
  display: none;
}
@media only screen and (max-width: 959px) {
  nav.dektop-view.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .v-main {
    padding: 56px 0px 0px 0px !important;
  }
}
</style>
