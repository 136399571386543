export const faq = [
  {
    q: 'How long is a RAVE VIP session?',
    a:
      "You can book 5-, 10-, or 25-minute Rave VIP sessions depending on the artist's preference and availability.",
  },
  {
    q: 'When will I be charged for my RAVE VIP session?',
    a:
      'Your credit card will be charged at the time of booking your RAVE VIP experience.',
  },
  {
    q:
      'If for some reason I must cancel my RAVE VIP session, due to some unforeseen reason, am I still liable for the full amount for that session?',
    a:
      'No, if for some reason you are unable to make the RAVE VIP session you originally secured, then a 50% penalty (up to 24 hours prior) will be assessed to compensate for the artist for setting aside that time in their schedule. RAVE VIP would then credit 1/2 of the amount you were originally charged to the card on file. If you cancel with less than 24 hours, you will not be eligible for a refund.', // eslint-disable-line
  },
  {
    q:
      'Am I allowed to video record, or take pictures of the screen while having a RAVE VIP experience?',
    a:
      'No, taking video (whether by you, or a third party in the room) is strictly prohibited (refer to the Terms of Service Agreement). Each artist will hold in his/her discretion if they would like to provide a copy of the RAVE VIP session to a fan. Depending on the artist, there may be an additional charge for the video, or photo footage.', // eslint-disable-line
  },
  {
    q: 'How often will new RAVE VIP openings be made available?',
    a:
      'The Artist/Talent is in control of their schedule and will make new openings according to their availability. Check back frequently and follow RAVE and the talent on social media to be the 1st to hear about new availability.', // eslint-disable-line
  },
]

export const ratings = [
  {
    reviewer: {
      name: 'Jo Ferrari',
      comment:
        'What a thrilling way to connect and have such a personalized, up-close and intimate one-on-one conversation with you, Eva!',
    },
    score: 5,
    talent: {
      profile: 'https://platform.hexly.cloud/assets/1007/12537',
      name: 'Eva Gardner',
      type: 'Musician',
    },
  },
  {
    reviewer: {
      name: 'Tom Roach',
      comment:
        'This is a great site!  It was GREAT to meet with Nathan Osmond!!',
    },
    score: 5,
    talent: {
      profile: 'https://platform.hexly.cloud/assets/1007/12541',
      name: 'Nathan Osmond',
      type: 'Musician',
    },
  },
  {
    reviewer: {
      name: 'Cheryle Pegues James',
      comment:
        'THIS IS THE COOLEST THING EVER. Check it out, you will LOVE it!',
    },
    score: 5,
    talent: {
      profile: 'https://platform.hexly.cloud/assets/1007/12541',
      name: 'Nathan Osmond',
      type: 'Musician',
    },
  },
]

export const sessionDurations = [
  { text: '5 Minutes', value: 5 },
  { text: '6 Minutes', value: 6 },
  { text: '7 Minutes', value: 7 },
  { text: '8 Minutes', value: 8 },
  { text: '9 Minutes', value: 9 },
  { text: '10 Minutes', value: 10 },
  { text: '11 Minutes', value: 11 },
  { text: '12 Minutes', value: 12 },
  { text: '13 Minutes', value: 13 },
  { text: '14 Minutes', value: 14 },
  { text: '15 Minutes', value: 15 },
  { text: '20 Minutes', value: 20 },
  { text: '25 Minutes', value: 25 },
  { text: '30 Minutes', value: 30 },
  { text: '35 Minutes', value: 35 },
  { text: '40 Minutes', value: 40 },
  { text: '45 Minutes', value: 45 },
  { text: '50 Minutes', value: 50 },
  { text: '55 Minutes', value: 55 },
  { text: '60 Minutes', value: 60 },
]

export const countryCode = {
  '+1-242': 'BS',
  '+1-246': 'BB',
  '+1-264': 'AI',
  '+1-268': 'AG',
  '+1-284': 'VG',
  '+1-340': 'VI',
  '+1-345': 'KY',
  '+1-441': 'BM',
  '+1-473': 'GD',
  '+1-649': 'TC',
  '+1-664': 'MS',
  '+1-670': 'MP',
  '+1-671': 'GU',
  '+1-684': 'AS',
  '+1-758': 'LC',
  '+1-767': 'DM',
  '+1-784': 'VC',
  '+1-787 and 1-939': 'PR',
  '+1-809 and 1-829': 'DO',
  '+1-868': 'TT',
  '+1-869': 'KN',
  '+1-876': 'JM',
  '+358-18': 'AX',
  '+44-1481': 'GG',
  '+44-1534': 'JE',
  '+44-1624': 'IM',
  '1': 'US',
  '20': 'EG',
  '211': 'SS',
  '212': 'MA',
  '213': 'DZ',
  '216': 'TN',
  '218': 'LY',
  '220': 'GM',
  '221': 'SN',
  '222': 'MR',
  '223': 'ML',
  '224': 'GN',
  '225': 'CI',
  '226': 'BF',
  '227': 'NE',
  '228': 'TG',
  '229': 'BJ',
  '230': 'MU',
  '231': 'LR',
  '232': 'SL',
  '233': 'GH',
  '234': 'NG',
  '235': 'TD',
  '236': 'CF',
  '237': 'CM',
  '238': 'CV',
  '239': 'ST',
  '240': 'GQ',
  '241': 'GA',
  '242': 'CG',
  '243': 'CD',
  '244': 'AO',
  '245': 'GW',
  '246': 'IO',
  '248': 'SC',
  '249': 'SD',
  '250': 'RW',
  '251': 'ET',
  '252': 'SO',
  '253': 'DJ',
  '254': 'KE',
  '255': 'TZ',
  '256': 'UG',
  '257': 'BI',
  '258': 'MZ',
  '260': 'ZM',
  '261': 'MG',
  '262': 'YT',
  '263': 'ZW',
  '264': 'NA',
  '265': 'MW',
  '266': 'LS',
  '267': 'BW',
  '268': 'SZ',
  '269': 'KM',
  '27': 'ZA',
  '290': 'SH',
  '291': 'ER',
  '297': 'AW',
  '298': 'FO',
  '299': 'GL',
  '30': 'GR',
  '31': 'NL',
  '32': 'BE',
  '33': 'FR',
  '34': 'ES',
  '350': 'GI',
  '351': 'PT',
  '352': 'LU',
  '353': 'IE',
  '354': 'IS',
  '355': 'AL',
  '356': 'MT',
  '357': 'CY',
  '358': 'FI',
  '359': 'BG',
  '36': 'HU',
  '370': 'LT',
  '371': 'LV',
  '372': 'EE',
  '373': 'MD',
  '374': 'AM',
  '375': 'BY',
  '376': 'AD',
  '377': 'MC',
  '378': 'SM',
  '379': 'VA',
  '380': 'UA',
  '381': 'RS',
  '382': 'ME',
  '385': 'HR',
  '386': 'SI',
  '387': 'BA',
  '389': 'MK',
  '39': 'IT',
  '40': 'RO',
  '41': 'CH',
  '420': 'CZ',
  '421': 'SK',
  '423': 'LI',
  '43': 'AT',
  '44': 'GB',
  '45': 'DK',
  '46': 'SE',
  '47': 'NO',
  '48': 'PL',
  '49': 'DE',
  '500': 'FK',
  '501': 'BZ',
  '502': 'GT',
  '503': 'SV',
  '504': 'HN',
  '505': 'NI',
  '506': 'CR',
  '507': 'PA',
  '508': 'PM',
  '509': 'HT',
  '51': 'PE',
  '52': 'MX',
  '53': 'CU',
  '54': 'AR',
  '55': 'BR',
  '56': 'CL',
  '57': 'CO',
  '58': 'VE',
  '590': 'MF',
  '591': 'BO',
  '592': 'GY',
  '593': 'EC',
  '594': 'GF',
  '595': 'PY',
  '596': 'MQ',
  '597': 'SR',
  '598': 'UY',
  '599': 'SX',
  '60': 'MY',
  '61': 'AU',
  '62': 'ID',
  '63': 'PH',
  '64': 'NZ',
  '65': 'SG',
  '66': 'TH',
  '670': 'TL',
  '672': 'NF',
  '673': 'BN',
  '674': 'NR',
  '675': 'PG',
  '676': 'TO',
  '677': 'SB',
  '678': 'VU',
  '679': 'FJ',
  '680': 'PW',
  '681': 'WF',
  '682': 'CK',
  '683': 'NU',
  '685': 'WS',
  '686': 'KI',
  '687': 'NC',
  '688': 'TV',
  '689': 'PF',
  '690': 'TK',
  '691': 'FM',
  '692': 'MH',
  '7': 'KZ',
  '81': 'JP',
  '82': 'KR',
  '84': 'VN',
  '850': 'KP',
  '852': 'HK',
  '853': 'MO',
  '855': 'KH',
  '856': 'LA',
  '86': 'CN',
  '870': 'PN',
  '880': 'BD',
  '886': 'TW',
  '90': 'TR',
  '91': 'IN',
  '92': 'PK',
  '93': 'AF',
  '94': 'LK',
  '95': 'MM',
  '960': 'MV',
  '961': 'LB',
  '962': 'JO',
  '963': 'SY',
  '964': 'IQ',
  '965': 'KW',
  '966': 'SA',
  '967': 'YE',
  '968': 'OM',
  '970': 'PS',
  '971': 'AE',
  '972': 'IL',
  '973': 'BH',
  '974': 'QA',
  '975': 'BT',
  '976': 'MN',
  '977': 'NP',
  '98': 'IR',
  '992': 'TJ',
  '993': 'TM',
  '994': 'AZ',
  '995': 'GE',
  '996': 'KG',
  '998': 'UZ',
}

export const tzStrings = [
  { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
  { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
  { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
  { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
  {
    label: '(GMT-08:00) Pacific Time (US & Canada)',
    value: 'America/Los_Angeles',
  },
  { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
  { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
  {
    label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    value: 'America/Chihuahua',
  },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
  { label: '(GMT-06:00) Central America', value: 'America/Managua' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
  {
    label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    value: 'America/Mexico_City',
  },
  { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
  {
    label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
    value: 'America/Bogota',
  },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
  { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
  { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
  { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
  { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
  { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
  { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
  {
    label: '(GMT-03:00) Buenos Aires, Georgetown',
    value: 'America/Argentina/Buenos_Aires',
  },
  { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
  { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
  { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
  { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
  { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  {
    label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
    value: 'Africa/Casablanca',
  },
  {
    label:
      '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    value: 'Etc/Greenwich',
  },
  {
    label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Amsterdam',
  },
  {
    label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 'Europe/Belgrade',
  },
  {
    label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    value: 'Europe/Brussels',
  },
  {
    label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    value: 'Europe/Sarajevo',
  },
  { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
  { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
  { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens' },
  { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
  { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
  {
    label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 'Europe/Helsinki',
  },
  { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
  { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
  { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
  {
    label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    value: 'Europe/Moscow',
  },
  { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
  { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
  { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
  { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi' },
  {
    label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'Asia/Calcutta',
  },
  { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
  { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
  { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
  { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
  { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  {
    label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'Asia/Hong_Kong',
  },
  { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur' },
  { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
  { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
  { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
  { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
  { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
  { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
  {
    label: '(GMT+10:00) Canberra, Melbourne, Sydney',
    value: 'Australia/Canberra',
  },
  { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
  { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
  { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
  {
    label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    value: 'Asia/Magadan',
  },
  { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
  { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
  { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
]

export const weekDays = [
  { text: 'Sunday', value: 'sunday', days: [] },
  { text: 'Monday', value: 'monday', days: [] },
  { text: 'Tuesday', value: 'tuesday', days: [] },
  { text: 'Wednesday', value: 'wednesday', days: [] },
  { text: 'Thursday', value: 'thursday', days: [] },
  { text: 'Friday', value: 'friday', days: [] },
  { text: 'Saturday', value: 'saturday', days: [] },
]
