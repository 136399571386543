<template>
  <ol class="day-of-week">
    <li
      v-for="weekday in weekdays"
      :key="weekday"
    >
      {{ weekday }}
    </li>
  </ol>
</template>

<script>
const WEEKDAYS = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
const WEEKDAYS_MOBILE = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]

export default {
  name: "CalendarWeekdays",

  computed: {
    weekdays() {
      if(this.$device.mobile) return WEEKDAYS_MOBILE;
      else return WEEKDAYS;
    },
  },
};
</script>

<style scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: center;
  padding-right: 5px;
  list-style: none;
  font-size: 15px;
}
</style>
