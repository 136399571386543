<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar app color="white" light flat>
      <v-app-bar-nav-icon
        v-if="!sidebarMenu&&!$device.mobile"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
      <MobileNavBar v-if="$device.mobile" />
      <v-spacer />
      <NotificationButton />
      <v-menu offset-y class="flex-column" left>
        <template #activator="{ on, attrs }">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-avatar class="rounded ma-0" size="47">
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="ProfileImg"
                  :src="profileImage"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
          <!-- <v-btn icon>
            <v-icon
              dark
              color="black"
            >
              mdi-bell-outline
            </v-icon>
          </v-btn> -->
        </template>
        <v-list>
          <v-list-item to="/talent/dashboard">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/account/settings">
            <v-list-item-title>{{ $t('account_settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Sidebar -->
    <NavBarList :sidebar.sync="sidebarMenu" />
    <!-- Main Container -->
    <v-container class="py-0 fill-height dashboard-container grey" fluid>
      <!-- Start: header section -->
      <v-row class="availablity-header">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="12"
          class="text-center pa-6 pt-sm-2 pb-sm-2 session-card-section"
        >
          <v-card-title class="text-h3 light-text pa-0 mt-5 availability">
            {{ $t('availability') }}
          </v-card-title>
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="text-center pa-6 pt-sm-2 pb-sm-2 session-card-section"
        >
          <v-btn-toggle
            v-model="toggleBtnText"
            tile
            color="deep-purple accent-3"
            class="availability-toggler"
            group
          >
            <v-btn value="date" class="no-shadow">
              {{ $t('date') }}
            </v-btn>

            <v-btn value="calendar" class="no-shadow">
              {{ $t('calendar') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          xl="5"
          lg="5"
          md="5"
          sm="12"
          xs="12"
          class="text-center pa-6 pt-sm-2 pb-sm-2 session-card-section"
        >
          <v-form ref="timezoneForm" lazy-validation>
            <v-select
              v-model="timeZone"
              :items="tzStrings"
              item-text="label"
              item-value="value"
              class="availability-selection"
              solo
              :label="$t('select_timezone')"
              :rules="rules.required"
            />
          </v-form>
        </v-col>
      </v-row>
      <!-- End: header section -->
      <!-- Start: Week Days -->
      <v-row v-if="toggleBtnText === 'date'">
        <v-form ref="sessionAvailabilityForm" lazy-validation class="form">
          <AlertBox
            v-if="weekDayError"
            :message="weekDayError"
            classes="mb-3"
          />
          <AlertBox
            v-if="success"
            :message.sync="success"
            :isError="isError"
            color="light-green lighten-3"
          />
          <v-col xl="12" lg="12" md="12" sm="12" xs="12" class="pa-6">
            <v-card-actions>
              <v-btn :loading="loading" color="primary" @click="saveWeekDays()">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col xl="4" lg="5" md="5" sm="12" xs="12" class="pa-6">
            <v-card elevation="0" :disabled="loading">
              <v-card-text>
                <div v-for="(day, key) in weekDays" :key="key">
                  <v-checkbox
                    v-model="selectedDay"
                    :label="day.text"
                    :value="day.value"
                    off-icon="mdi-checkbox-blank-circle-outline"
                    on-icon="mdi-checkbox-marked-circle-outline"
                    class="cust-checkbox"
                    @change="dayCheckboxToggle(day.value, key)"
                  />

                  <v-container v-if="day.days.length">
                    <v-row
                      v-for="(dayTime, indexKey) in day.days"
                      :key="dayTime.id"
                    >
                      <v-col cols="12" sm="6" md="5">
                        <VueTimepicker
                          v-model="dayTime.startTime"
                          format="h:mm A"
                          manual-input
                          lazy
                          close-on-complete
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <VueTimepicker
                          v-model="dayTime.endTime"
                          format="h:mm A"
                          manual-input
                          lazy
                          close-on-complete
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-icon @click="deleteTimeSlot(key, indexKey)">
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-btn class="no-shadow addBtn" @click="addNew(key)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-container>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-form>
      </v-row>
      <!-- End: week days -->
      <!-- START: calendar -->
      <v-row v-if="toggleBtnText !== 'date'">
        <v-col xl="12" lg="12" md="12" sm="12" xs="12" class="pa-6">
          <CalendarMonth :time-zone="timeZone" />
        </v-col>
      </v-row>
      <!-- END: calendar -->
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
var uniqid = require('uniqid')

// graphql
import {
  FETCH_WEEK_DAY_AVAILABILITY,
  SET_WEEK_DAY_AVAILABILITY,
} from '@/graphql/booking'

// Components
import NotificationButton from '@/components/generic/NotificationButton.vue'
import MobileNavBar from '@/components/generic/MobileNavBar.vue'
import NavBarList from '@/pages/talent/dashboard/NavBarList.vue'
import CalendarMonth from '@/components/calendar/CalendarMonth'
//Unitl
import { rules } from '@/utils/validation'
import { tzStrings, weekDays } from '@/utils/static'
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    NotificationButton,
    MobileNavBar,
    NavBarList,
    CalendarMonth,
    VueTimepicker,
  },
  data() {
    return {
      rules,
      icon: null,
      tzStrings,
      weekDays,
      selectedDay: [],
      sidebarMenu: true,
      toggleMini: false,
      selectedSession: null,
      error: null,
      sessions: [],
      isListDisabled: false,
      isShow: false,
      timeZone: this.$defaultTZ,
      toggleBtnText: 'date',
      weekDayError: null,
      loading: false,
      today: this.$moment().format('YYYY-MM-DD'),
      success: null,
      isError: false,
    }
  },
  computed: {
    ...mapGetters(['profileImage', 'user', 'jwt']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
  },
  beforeMount() {
    this.icon = '/images/icon/talent/menu.png'
    if(this.$device.mobile) this.sidebarMenu = false;
  },
  watch: {
    weekDays: {
      deep: true,
      handler(newVal, oldVal) {},
    },
  },
  async mounted() {
    await this.fetchWeekdayAvailability()
  },
  methods: {
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },

    dayCheckboxToggle(e, key) {
      if (this.selectedDay.includes(e)) {
        this.weekDays[key].days = [
          {
            startTime: '',
            endTime: '',
            id: uniqid(),
          },
        ]
      } else {
        this.weekDays[key].days = []
      }
    },

    addNew(key) {
      this.weekDays[key].days.push({
        startTime: '',
        endTime: '',
        id: uniqid(),
      })
    },
    deleteTimeSlot(key, indexKey) {
      this.weekDays[key].days.splice(indexKey, 1)

      if (this.weekDays[key].days.length == 0) {
        this.selectedDay = this.selectedDay.filter(
          id => id !== this.weekDays[key].value
        )
      }
    },
    /**
     * Get Week day Availability Details
     */
    async fetchWeekdayAvailability() {
      try {
        const {
          data: { fetchWeekdayAvailability },
        } = await this.$apollo.query({
          query: FETCH_WEEK_DAY_AVAILABILITY,
          fetchPolicy: 'no-cache',
          variables: {
            authInput: {
              email: this.user.email,
              accesstoken: this.jwt,
              tzinfo: this.$defaultTZ,
            },
          },
        })

        if (fetchWeekdayAvailability.success) {
          var availabilityRes = fetchWeekdayAvailability.availability
          const availArr = []
          for (let index = 0; index < availabilityRes.length; index++) {
            var reteiveSlots = []
            var slotData = availabilityRes[index].slots
            for (let k = 0; k < slotData.length; k++) {
              reteiveSlots.push({
                startTime: this.$moment(
                  `${this.today} ${slotData[k].startTime}`
                ).format('hh:mm A'),
                endTime: this.$moment(
                  `${this.today} ${slotData[k].endTime}`
                ).format('hh:mm A'),
                id: uniqid(),
              })
            }
            availArr[availabilityRes[index].weekday] = reteiveSlots
          }

          weekDays.forEach(ele => {
            if (availArr[ele.value]) {
              ele.days = availArr[ele.value]
              if (availArr[ele.value].length) {
                this.selectedDay.push(ele.value)
              }
            }
          })
        } else {
          this.weekDayError = this.$t(fetchWeekdayAvailability.error)
        }
      } catch (e) {
        console.error(e) // eslint-disable-line
      }
    },
    async saveWeekDays() {
      if (
        this.$refs.sessionAvailabilityForm.validate() &&
        this.$refs.timezoneForm.validate()
      ) {
        // const selectedWeekDays = this.weekDays.filter(data => this.selectedDay.includes(data.value));
        const selectedWeekDays = this.weekDays
        var error = false
        for (let i = 0; i < selectedWeekDays.length; i++) {
          if (selectedWeekDays[i].days.length) {
            var validDay = selectedWeekDays[i].days
            for (let j = 0; j < validDay.length; j++) {
              if (
                validDay[j].startTime == '' ||
                !this.$moment(`${this.today} ${validDay[j].startTime}`)._isValid
              ) {
                error = true
                break
              }
              if (
                validDay[j].endTime == '' ||
                !this.$moment(`${this.today} ${validDay[j].endTime}`)._isValid
              ) {
                error = true
                break
              }
            }

            if (error) {
              break
            }
          }
        }
        if (error) {
          this.$toasted.error(this.$t('valid_slot_time'))
        }

        const availEntry = selectedWeekDays.map(weekDay => {
          var slots = []
          if (weekDay.days.length) {
            slots = weekDay.days.map(day => {
              return {
                startTime: this.$moment(
                  `${this.today} ${day.startTime}`
                ).format('HH:mm'),
                endTime: this.$moment(`${this.today} ${day.endTime}`).format(
                  'HH:mm'
                ),
              }
            })
          }
          return {
            slots,
            weekday: weekDay.value,
          }
        })

        this.loading = true
        try {
          const {
            data: { setWeekdayAvailability },
          } = await this.$apollo.mutate({
            mutation: SET_WEEK_DAY_AVAILABILITY,
            variables: {
              availableData: {
                email: this.user.email,
                accesstoken: this.jwt,
                tzinfo: this.timeZone,
                availEntry: availEntry,
              },
            },
          })

          if (setWeekdayAvailability.success) {
            await this.fetchWeekdayAvailability()
            this.success = this.$t('weekday_availability_set')
            this.isError = false
          } else {
            this.weekDayError = this.$t(setWeekdayAvailability.error)
          }
          this.loading = false
        } catch (e) {
          this.weekDayError = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.availability {
  display: flex;
  align-items: center;
}

.availability-toggler {
  min-width: 170px;
  background: #fff;
}

.availability-toggler .v-btn--active::before {
  background: #ffb421 !important;
  color: #fff !important;
  opacity: 1;
  border-radius: 5px;
}

.availability-toggler .v-btn--active {
  color: #fff !important;
}
</style>
<style scoped>
.form {
  width: 100%;
}
.cust-checkbox >>> .v-icon,
.cust-checkbox >>> .v-label {
  color: #333;
}
.cust-checkbox >>> .primary--text {
  color: #333 !important;
}
.custom-input >>> .v-text-field__slot {
  height: 50px;
}
.custom-input >>> fieldset {
  height: 50px;
}
.custom-input >>> input {
  color: rgb(0, 0, 0);
  padding: 13px 0;
}
button.addBtn {
  box-shadow: none;
  min-width: 60px !important;
  background: #f1f3f6 !important;
}

.custom-input >>> .v-input__slot {
  align-items: stretch;
  min-height: auto;
  margin-bottom: 0;
}
.availability-selection >>> .v-input__slot {
  border: 1px solid #d9dbdc;
  background: transparent !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 768px) {
  .search-box.v-card.v-sheet.theme--light {
    display: none;
  }
  main.v-main {
    padding-left: 0px !important;
  }
  nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--mini-variant.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .v-image.v-responsive.elevation-6.theme--light {
    display: block;
  }
  .availablity-header {
    display: block;
  }
}
</style>
