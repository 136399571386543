<template>
  <li
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
    }"
  >
    <div
      class="date-wrap calendar-day-col"
      @click="day.isCurrentMonth && !$moment(day.date).isBefore($moment(), 'days') ? triggerPopup(day) : ''"
    >
      <v-checkbox
        v-if="!this.$device.mobile"
        v-model="selectedDay"
        :value="label"
        off-icon="mdi-checkbox-blank-circle-outline"
        on-icon="mdi-checkbox-marked-circle-outline"
        class="cust-checkbox mt-0"
        :disabled="!day.isCurrentMonth || $moment(day.date).isBefore($moment(), 'days')"
      >
        <template slot="label">
          {{ label }} <span
            v-if="isToday"
            class="today float-right"
          >Today</span>
        </template>
      </v-checkbox>
      <div v-else>
        <!-- <div v-if="slots.slots.length">/</div>
        <div v-else>'</div> -->
        <v-icon v-if="slots.length != 0" class="cal-event-check-mobile">
          mdi-circle
        </v-icon>
        <div v-else class="cal-event-check-mobile"></div>
        <div>{{ label }}</div>
        <div
          v-if="isToday"
          class="today"
        >Today</div>
      </div>
      <div class="mt-n4 cal-session-li">
        <ul
          class="pl-0"
        >
          <li 
            v-for="time, k in slots.slots" 
            :key="k"
            class="slots"
          >
            {{ $moment(`${day.date} ${time.startTime}`).format('hh:mm A') }} - {{ $moment(`${day.date} ${time.endTime}`).format('hh:mm A') }}
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="openModel && day.date == targetDate" 
      class="model pa-3"
    >
      <label class="mb-5 d-inline-block">{{ title }}</label>
      <v-icon class="close-slot-modal" @click="$emit('closePopup')">mdi-close</v-icon>
      <v-container>
        <v-row>
          <v-form
            ref="calendarForm"
            lazy-validation
            class="form"
          >
            <div
              v-for="dayTime, key in editingSlots" 
              :key="dayTime.id"
            >
              <v-col
                cols="12"
                sm="6"
                md="5"
                class="d-inline-block vuePicker"
              >
                <VueTimepicker 
                  v-model="dayTime.startTime" 
                  format="h:mm A" 
                  manual-input 
                  lazy 
                  close-on-complete
                />
              </v-col>  
              <v-col
                cols="12"
                sm="6"
                md="5"
                class="d-inline-block vuePicker"
              >
                <VueTimepicker 
                  v-model="dayTime.endTime" 
                  format="h:mm A" 
                  manual-input 
                  lazy 
                  close-on-complete
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
                class="d-inline-block"
              >
                <v-icon @click="deleteTimeSlot(key)">
                  mdi-delete
                </v-icon>
              </v-col>
            </div>
            <v-btn
              class="no-shadow addBtn"
              small
              @click="addNew()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn 
              :loading="loading"
              color="primary float-right no-shadow" 
              small
              @click="saveCalendarDays()"
            >
              {{ $t("save") }}
            </v-btn>
            <AlertBox
              :message="weekDayError"
              classes="ma-3"
            />
            <v-divider class="mt-5 mb-5" />
            <v-select
              v-model="setRepeat"
              :items="repeatVal"
              class="availability-week"
              solo
            />
          </v-form>
        </v-row>
      </v-container>
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
var uniqid = require('uniqid');
//Unitl
import VueTimepicker from 'vue2-timepicker'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "CalendarMonthDayItem",
  components:{
    VueTimepicker,
  },
  props: {
    day: {
      type: Object,
      required: true,
    },

    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
    availabilities: {
      type: Array,
      required: true,
    },
    openModel: {
      type: Boolean,
      default: false,
    },
    targetDate: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    existingSlots:{
      type: Array,
      default: null,
    },
    repeat:{
      type: String,
      default: null,
    },
    weekDayError:{
      type: String,
      default: null,
    },
  },
  data(){
    return {
      setRepeat: this.repeat,
      editingSlots: [],
      selectedDay: [],
      mask: this.timeRangeMask,
      loading: false,
      calendarError: null,
      repeatVal: [ 'No Repeat', 'Every Week', 'Every Month' ],
    }
  },
  computed: {
    label() {
      return dayjs(this.day.date).format("D");
    },
    slots(){
      const arr = this.availabilities.filter(day => (day.date == this.day.date && day.slots.length ))
      return arr[0] || []
    },
  },
  watch:{
    availabilities:{
      deep: true,
      handler(newVal){
        this.selectedDay = []
        for (let i = 0; i < newVal.length; i++) {
          if(newVal[i].date == this.day.date && newVal[i].slots.length){
            this.selectedDay.push(dayjs(this.day.date).format("D"))
          }
        }
      },
    },
    existingSlots: {
      deep: true,
      handler(b){
        this.editingSlots = b
      },
    },
    setRepeat(e){
      this.$emit('update:repeat', e)
    },
  },
  methods:{
    triggerPopup(object){
      this.$emit('showPopup', object);
    },
    timeMask(value) {
      const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
      ];
      const minutes = [ /[0-5]/, /[0-9]/ ];
      return value.length > 2
        ? [ ...hours, ':', ...minutes ]
        : hours;
    },

    /**
     * Generate a time range mask based on input value (00:00-23:59)
     * @param {string} value
     */
    timeRangeMask(value) {
      const numbers = value.replace(/[^0-9]/g, '');
      return [ ...this.timeMask(numbers) ];
    },
    addNew(){
      this.editingSlots.push({
        startTime: '',
        endTime: '',
        id: uniqid(),
      })
    },
    deleteTimeSlot(key){
      this.editingSlots.splice(key, 1)

      if(this.editingSlots.length == 0){
        this.selectedDay = this.selectedDay.filter(id => id !== this.label)
      }
    },
    async saveCalendarDays(){
      if(this.$refs.calendarForm.validate()){
        this.$emit('updateCalendar', this.day);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .calendar-day {
    position: relative;
    min-height: 100px;
    font-size: 16px;
    background-color: #fff;
    color: $grey-eight;
    padding: 5px;
  }

  .calendar-day--not-current {
    background-color: $grey-one;
    color: $grey-three;
  }

  .calendar-day--today {
    padding-top: 4px;
  }

  .calendar-day--today > span {
    color: #fff;
    border-radius: 9999px;
    background-color: $grey-eight;
  }
  .today{
      width: 100%;
      text-align: right;
      color: $primary;
  }
  .slots{
    list-style: none;
    font-size: 10px;
  }
  .close-slot-modal {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    .today {
      font-size: 11px;
    }
    .calendar-day {
      min-height: 65px;
      position: initial;
      .calendar-day-col {
        min-height: 55px;
        text-align: center;
      }
      .cal-session-li {
        display: none;
      }
      .cal-event-check-mobile {
        font-size: 10px;
        color: #f7b500;
        min-height: 25px;
      }
    }
    div.model {
      left: 0px !important;
      right: 0px !important;
      width: 100% !important;
    }
    .close-slot-modal {
      float: right;
      display: flex;
    }
  }
</style>
<style scoped>
  .cust-checkbox >>> label{
    font-size: 12px;
    color: #333
  }
  .cust-checkbox >>> .v-icon, .cust-checkbox >>> .v-label{
    color: #333 ;
  }
  .cust-checkbox >>> .primary--text{
    color: #333 !important;
  }
  .calendar-day div.model{
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    background: #fff;
    box-shadow: 1px 1px 9px 0px #333;
    z-index: 9;
    max-height: 300px;
    overflow: auto;
  }
   
  li:nth-last-child(-n+7).calendar-day div.model{
    bottom:0px;
    top:auto;
  }
  ol > li:nth-child(7n).calendar-day div.model {
    left:auto;
    right:100%;
  }
  ol > li:nth-child(7n-1).calendar-day div.model {
    left:auto;
    right:100%;
  }

  .form{
    width: 100%;
  }
  .availability-week >>> .v-input__slot{
    box-shadow: none !important;
  }
  .availability-week >>> .v-text-field__details{
    display: none;
  }
  .date-wrap{
    min-height: 90px;
    cursor: pointer;
  }
  .custom-input >>> input{
    font-size: 12px;
  }
  .vuePicker >>> span, .vuePicker >>> span input{
    width: 100px;
  }
</style>