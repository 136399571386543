<template>
  <div class="calendar-date-selector">
    <span
      class="mr-5"
      @click="selectPrevious"
    >
      <v-icon
        dark
        color="black"
        large
      >
        mdi-chevron-left
      </v-icon>
    </span>
    <!-- <span @click="selectCurrent">Today</span> -->
    <span @click="selectNext">
      <v-icon
        dark
        color="black"
        large
      >
        mdi-chevron-right
      </v-icon>
    </span>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarModeSelector",
  props: {
    currentDate: {
      type: String,
      required: true,
    },

    selectedDate: {
      type: Object,
      required: true,
    },
  },

  methods: {
    selectPrevious() {
      let newSelectedDate = dayjs(this.selectedDate).subtract(1, "month");
      this.$emit("dateSelected", newSelectedDate);
      this.$emit('getAvailability')
    },

    selectCurrent() {
      let newSelectedDate = dayjs(this.currentDate);
      this.$emit("dateSelected", newSelectedDate);
    },

    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).add(1, "month");
      this.$emit("dateSelected", newSelectedDate);
      this.$emit('getAvailability')
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-date-selector {
  display: inline-block;
  color: $grey-eight;
}

.calendar-date-selector > * {
  cursor: pointer;
  user-select: none;
}
</style>
