<template>
  <div class="calendar-month">
    <div class="calendar-month-header mb-5">
      <CalendarDateIndicator
        :selected-date="selectedDate"
        class="calendar-month-header-selected-month"
      />

      <CalendarDateSelector
        :current-date="today"
        :selected-date="selectedDate"
        @dateSelected="selectDate"
        @getAvailability="fetchMonthAvailability"
      />
    </div>

    <CalendarWeekdays />

    <ol class="days-grid">
      <CalendarMonthDayItem
        v-for="day in days"
        :key="day.date"
        :day="day"
        :is-today="day.date === today"
        :availabilities="availabilities"
        :open-model="openModel"
        :target-date="targetDate"
        :title="title"
        :week-day-error="weekDayError"
        :repeat.sync="repeat"
        :existing-slots.sync="existingSlots"
        @showPopup="openModelWindow"
        @closePopup="closeModelWindow"
        @updateCalendar="setMultiAvailability"
      />
    </ol>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
var uniqid = require('uniqid');
// graphql
import { FETCH_MONTH_AVAILABILITY, SET_MULTI_AVAILABILITY } from "@/graphql/booking";

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import CalendarMonthDayItem from "./CalendarMonthDayItem";
import CalendarDateIndicator from "./CalendarDateIndicator";
import CalendarDateSelector from "./CalendarDateSelector";
import CalendarWeekdays from "./CalendarWeekdays";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
var trigerDate = '';
export default {
  name: "CalendarMonth",

  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
  },
  props: {
    timeZone: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedDate: dayjs(),
      availabilities: [],
      openModel: false,
      targetDate: null,
      title: null,
      existingSlots: [],
      repeat: 'No Repeat',
      weekDayError: null,
    };
  },
  computed: {
    ...mapGetters([ 'user', 'jwt' ]),
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },

    today() {
      return dayjs().format("YYYY-MM-DD");
    },

    month() {
      return Number(this.selectedDate.format("M"));
    },

    year() {
      return Number(this.selectedDate.format("YYYY"));
    },

    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },

    currentMonthDays() {
      return [ ...Array(this.numberOfDaysInMonth) ].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format("YYYY-MM-DD"),
          isCurrentMonth: true,
        };
      });
    },

    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(this.currentMonthDays[0].date);
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(1,"month");
      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday
        : 0;

      const previousMonthLastMondayDayOfMonth = dayjs(this.currentMonthDays[0].date)
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [ ...Array(visibleNumberOfDaysFromPreviousMonth) ].map(
        (day, index) => {
          return {
            date: dayjs( `${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format("YYYY-MM-DD"), 
            isCurrentMonth: false,
          };
        },
      );
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(`${this.year}-${this.month}-${this.currentMonthDays.length}`);
      
      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 6 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday + 6;

      return [ ...Array(visibleNumberOfDaysFromNextMonth) ].map((day, index) => {
        return {
          date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format("YYYY-MM-DD"),
          isCurrentMonth: false,
        };
      });
    },
  },
  watch:{
    existingSlots:{
      deep: true,
      handler(n){
      },
    },
  },
  async mounted(){
    await this.fetchMonthAvailability();
  },
  methods: {
    getWeekday(date) {
      return dayjs(date).weekday();
    },

    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },

    async fetchMonthAvailability(){
      try {
        const { data: { fetchMonthAvailability } } = await this.$apollo.query({
          query: FETCH_MONTH_AVAILABILITY,
          fetchPolicy:'no-cache',
          variables: {
            availInput: { 
              email: this.user.email,
              accesstoken: this.jwt,
              tzinfo: this.$defaultTZ,
              date: this.selectedDate.format('YYYY-MM-DD'),
            },
          },
        });

        if (fetchMonthAvailability.success) {
          this.availabilities = fetchMonthAvailability.availability;
          
        } else {
          this.monthlyError = this.$t(fetchWeekdayAvailability.error);
        }
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    },
    closeModelWindow() {
      this.openModel = false;
      this.targetDate = null;
      trigerDate = '';
    },
    openModelWindow(object){
      if(trigerDate == object.date){
        this.openModel = false;
        this.targetDate = null;
        trigerDate = '';
        return;
      }
      this.targetDate = object.date;
      trigerDate = this.targetDate;
      this.title = this.$moment(object.date).format('dddd, MMMM DD');
      this.existingSlots = [];
      for (let i = 0; i < this.availabilities.length; i++) {
        var slotsDays = [];
        if(this.availabilities[i].date == object.date){
          slotsDays = this.availabilities[i].slots;
          for (let k = 0; k < slotsDays.length; k++) {
            this.existingSlots.push({
              startTime: this.$moment(`${object.date} ${slotsDays[k].startTime}`).format('hh:mm A'),
              endTime: this.$moment(`${object.date} ${slotsDays[k].endTime}`).format('hh:mm A'),
              id: uniqid(),
            })
          }
        }
      }
      this.openModel = true;
    },
    async setMultiAvailability(object){
      var slots = [];
      this.weekDayError = null;
      var error = false;
      for (let j = 0; j < this.existingSlots.length; j++) {
        if(this.existingSlots[j].startTime == '' || !this.$moment(`${object.date} ${this.existingSlots[j].startTime}`)._isValid){
          error = true;
          break;
        }
        if(this.existingSlots[j].endTime == '' || !this.$moment(`${object.date} ${this.existingSlots[j].endTime}`)._isValid){
          error = true;
          break;
        }
      }
      if(error){
        this.$toasted.error(this.$t('valid_slot_time'))
      }
      
      if(this.existingSlots.length){
        slots = this.existingSlots.map(day => {
          return {
            startTime: this.$moment(`${object.date} ${day.startTime}`).format('HH:mm'),
            endTime: this.$moment(`${object.date} ${day.endTime}`).format('HH:mm'),
          }
        })
      }

      const availEntry = {
        slots,
        date: object.date,
        repeat: (this.repeat == 'Every Week' ? "weekly" : (this.repeat == 'Every Month' ? "monthly" : "no_repeat")),
      }

      try {
        const { data: { setMultiAvailability } } = await this.$apollo.mutate({
          mutation: SET_MULTI_AVAILABILITY,
          variables:{
            availableData: {
              email: this.user.email,
              accesstoken: this.jwt,
              tzinfo: this.timeZone,
              availEntry: availEntry,
            },
          },
        });

        if (setMultiAvailability.success) {
          this.fetchMonthAvailability();
          this.openModel = false;
          this.targetDate = null;
          trigerDate = '';
        } else {
          this.weekDayError = this.$t(setMultiAvailability.error);
        }
        this.loading = false;

      } catch (e) {
        this.weekDayError = e
        console.error(e); // eslint-disable-line
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.calendar-month {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .calendar-month-header {
    display: flex;
    justify-content: space-between;
  }
}

.day-of-week {
  color: $grey-eight;
  font-size: 18px;
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 8px 8px 0 0;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-left: 0px;
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
  list-style: none;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: $grid-gap;
  grid-row-gap: $grid-gap;
  border: solid 1px $grey-two;
  list-style: none;
  background: $grey-two;
}
</style>
