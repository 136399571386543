import gql from 'graphql-tag'

export const PAY_AND_BOOK_SESSION = gql`
  mutation PayAndBookSessionMutation($bookingData: CardBookSessionInput!) {
    payAndBookSession(bookingData: $bookingData){
      success
      bookedSession{
        id
        bookedBy{
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
          zipcode
          city
          country{
            id
            code
            name
            enabled
          }
          timezone{
            id
            name
          }
          language{
            id
            name
            localName
          }
        }
        sessionType{
          id
          title
          length
          charge
          user {
            id
            username
            firstName
            lastName
            email
            verifiedEmail
            profileImg
            phoneNumber
            zipcode
            city
            country{
              id
              code
              name
              enabled
            }
            timezone{
              id
              name
            }
            language{
              id
              name
              localName
            }
          }
        }
        date
        startTime
        endTime
        videochannel
        textchannel
      }
      error
      errorCode
    }
  }
`

export const UPSERT_BOOKING = gql`
  mutation bookingUpsert($input: ScheduleBookingInput!) {
    bookingUpsert: schedulingBookingUpsert(input: $input) {
      id
      typeId
      hostId
      guestId
      start
      end
    }
  }
`

export const GET_BOOKING = gql`
  query DisplayBookingOutput($bookinginput: BookingInput!, $pagination: PaginationInput, $tzinfo: String) {
    booking(bookinginput: $bookinginput, pagination: $pagination, tzinfo: $tzinfo) {
      success
      bookedList{
        id
        sessionType{
          id
          title
          length
          charge
        }
        host{
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
        }
        guest{
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          istalent
        }
        startTime
        endTime
      }
      first
      skip
      totalBookings
      error
      bookedListAppointments{
        id
        sessionType{
          id
          title
          length
          charge
        }
        host{
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
        }
        guest{
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          istalent
        }
        startTime
        endTime
      }
    }
  }
`

export const GET_BOOKING_REQUESTS = gql`
  query bookingRequests($input: BookingRequestInput!) {
    getBookingRequest(input: $input) {
      page
      totalPages
      pageSize
      totalResults
      results {
        id
        requestedId
        requesterId
        proposedOptions
        requester {
          displayName
        }
        requested {
          displayName
        }
        createdOn
      }
    }
  }
`

export const BOOKING_REQUEST_UPSERT = gql`
  mutation bookingRequestUpsert($input: BookingRequestUpsertInput!) {
    bookingRequestUpsert(input: $input) {
      id
      status
      requestedId
      requesterId
      proposedOptions
    }
  }
`

export const ADD_CARD = gql`
  mutation AddCardMutation($cardData: CardInput!) {
    addCard(cardData: $cardData) {
      success
      error
    }
  }
`

export const PAY_AND_EXTEND_SESSION = gql`
  mutation PayAndExtendSessionMutation($extensionData: ExtendSessionInput!) {
    payAndExtendSession(extensionData: $extensionData) {
      success
      error
      bookedSession{
        id
        bookedBy{
          id
          username
          firstName
          lastName
          email
          verifiedEmail
          profileImg
          phoneNumber
          zipcode
          city
          country{
            id
            code
            name
            enabled
          }
          timezone{
            id
            name
          }
          language{
            id
            name
            localName
          }
        }
        sessionType{
          id
          title
          length
          charge
          user {
            id
            username
            firstName
            lastName
            email
            verifiedEmail
            profileImg
            phoneNumber
            zipcode
            city
            country{
              id
              code
              name
              enabled
            }
            timezone{
              id
              name
            }
            language{
              id
              name
              localName
            }
          }
        }
        date
        startTime
        endTime
        videochannel
        textchannel
      }
      errorCode
    }
  }
`

export const EXTEND_SESSION_ALERT = gql`
  mutation ExtendSessionAlertMutation($extendAlertData: ExtendAlertInput!) {
    extendSessionAlert(extendAlertData: $extendAlertData) {
      success
      error
    }
  }
`

export const SET_WEEK_DAY_AVAILABILITY = gql`
  mutation SetWeekdayAvailability($availableData: WeekdayAvailabilityInput!) {
    setWeekdayAvailability(availableData: $availableData) {
      success
      error
    }
  }
`

export const FETCH_WEEK_DAY_AVAILABILITY = gql`
  query fetchWeekdayAvailability($authInput: FetchAvailabilityInput!) {
    fetchWeekdayAvailability(authInput: $authInput) {
      success
      error
      availability{
        available
        date
        weekday
        slots{
          startTime
          endTime
          tzinfo
        }
      }
      user {
        id
        username
        firstName
        lastName
        email
        verifiedEmail
        profileImg
        phoneNumber
        zipcode
        city
        country{
          id
          code
          name
          enabled
        }
        timezone{
          id
          name
        }
        language{
          id
          name
          localName
        }
      }
    }
  }
`

export const FETCH_MONTH_AVAILABILITY = gql`
  query fetchMonthAvailability($availInput: FetchMonthAvailabilityInput!) {
    fetchMonthAvailability(availInput: $availInput) {
      success
      error
      availability{
        available
        date
        weekday
        slots{
          startTime
          endTime
          tzinfo
        }
      }
      user {
        id
        username
        firstName
        lastName
        email
        verifiedEmail
        profileImg
        phoneNumber
        zipcode
        city
        country{
          id
          code
          name
          enabled
        }
        timezone{
          id
          name
        }
        language{
          id
          name
          localName
        }
      }
    }
  }
`

export const SET_MULTI_AVAILABILITY = gql`
  mutation SetMultiAvailability($availableData: MultiAvailabilityInput!) {
    setMultiAvailability(availableData: $availableData) {
      success
      error
    }
  }
`