<template>
  <v-menu offset-y class="flex-column" left>
    <template #activator="{ on, attrs }">
      <v-list>
        <v-list-item class="pa-0">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" />
        </v-list-item>
      </v-list>
    </template>
    <v-list class="mobile-nav-bar">
      <v-list-item to="/talent/dashboard">
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>
      <v-list-item to="/talent/sessions">
        <v-list-item-title>{{ $t('session_types') }}</v-list-item-title>
      </v-list-item>
      <v-list-item to="/talent/availability">
        <v-list-item-title>{{ $t('availability') }}</v-list-item-title>
      </v-list-item>
      <v-list-item to="/talent/appointments">
        <v-list-item-title>{{ $t('appointments') }}</v-list-item-title>
      </v-list-item>
      <v-list-item to="/talent/profile">
        <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout()">
        <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
