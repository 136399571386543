<template>
  <div class="calendar-date-indicator mr-5">
    {{ selectedMonth }}
  </div>
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: Object,
      required: true,
    },
  },

  computed: {
    selectedMonth() {
      return this.selectedDate.format("MMMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-date-indicator {
  font-size: $font-18;
  color: $black;
  display: inline-block;
  width: 150px;
}
</style>

